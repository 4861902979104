import {useState, useEffect} from 'react';

import {Formik, Field, Form} from 'formik';
import {isBefore, isAfter, isEqual} from 'date-fns';
import ReactGA from 'react-ga';
import ReactModal from 'react-modal';

import MapComponent from './components/Map';
import RaidList from './components/RaidList';
import Filters from './components/Filters';

import {META_CATEGORY_MAPPING} from '../constants';
import {processData} from '../utils';
import RAW_DATA from '../data';

const LAST_UPDATED = 'April 2022';
const flatten = (list) => list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

const intersection = (a1, a2) => {
  return a1.filter((value) => -1 !== a2.indexOf(value));
};

const DEFAULT_VIEWPORT = {
  latitude: 41.1120023083,
  longitude: -73.6403316334,
  zoom: 7.1,
};

const Index = () => {
  const [viewport, setViewport] = useState({...DEFAULT_VIEWPORT});
  const [query, setQuery] = useState('');
  const [searchHelpModalIsOpen, setSearchHelpModalIsOpen] = useState(false);

  // state
  const [searchBarOpen, setSearchBarOpen] = useState(true);
  const [activeRaid, _setActiveRaid] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // data
  const [raidsMap, setRaidsMap] = useState(null);
  const [locations, setLocations] = useState({data: [], loading: true});
  const [tactics, setTactics] = useState({data: [], loading: true});
  const [categories, setCategories] = useState({data: [], loading: true});

  // filter state
  const [minFrom, setMinFrom] = useState();
  const [maxUntil, setMaxUntil] = useState();
  const [filterFrom, setFilterFrom] = useState();
  const [filterUntil, setFilterUntil] = useState();
  const [filterCategories, setFilterCategories] = useState();
  const [filterTactics, setFilterTactics] = useState();
  const [filterCounties, setFilterCounties] = useState();
  const [defaultFilterCounties, setDefaultFilterCounties] = useState();

  const setActiveRaid = (raid) => {
    _setActiveRaid(raid);
    if (raid) {
      ReactGA.event({
        category: 'User',
        label: `click-raid`,
        action: `Clicked Raid ${raid.story_identifier}`,
      });
    }
  };

  // raids data
  const [raids, setRaids] = useState({data: [], loading: true});
  const [totalRaidCount, setTotalRaidCount] = useState(0);

  const resetTacticFilters = () => {
    for (let [key, value] of filterTactics.entries()) {
      value.toggled = false;
      filterTactics.set(key, value);
    }
  };

  const resetCountyFilters = () => {
    for (let [key, value] of filterCounties.entries()) {
      value.toggled = false;
      filterCounties.set(key, value);
    }
    setFilterCounties(filterCounties);
  };

  const resetFilters = () => {
    setFilterFrom(minFrom);
    setFilterUntil(maxUntil);
    setFilterCategories(filterCategories.map((c) => ({...c, toggled: true})));
    resetTacticFilters();
    resetCountyFilters();
    setQuery('');
  };

  useEffect(() => {
    // do this once: process the data and set the default filter values
    if (raids.data.length === 0) {
      let {
        raids,
        // raids_map,
        // locations,
        tactics,
        categories,
        filters,
      } = processData(RAW_DATA);
      // set data
      setRaidsMap(raidsMap);
      setLocations(locations);
      setTactics(tactics);
      setCategories(categories);

      let {
        from,
        minFrom,
        until,
        maxUntil,
        categories: filterCategories,
        tactics: filterTactics,
        counties: filterCounties,
      } = filters;
      // set filters
      console.debug(filters);
      setFilterFrom(from);
      setMinFrom(minFrom);
      setMaxUntil(maxUntil);
      setFilterUntil(until);
      setFilterCategories(filterCategories);
      setFilterTactics(filterTactics);
      setFilterCounties(filterCounties);
      setDefaultFilterCounties(filterCounties);

      // set raids
      setRaids({data: raids, loading: false});
      setTotalRaidCount(raids.length);
    }
    // eslint-disable-nextLine
  }, [raids.data.length]);

  const toggleCategory = (e) => {
    let category_name = e.target.id;
    console.debug(`Toggling Category:${category_name}`);
    let category_filters = [...filterCategories];
    category_filters.forEach((cat_filter) => {
      if (cat_filter.name === category_name) {
        console.debug(
          `Toggling category:${cat_filter.name} from ${
            cat_filter.toggled
          } to ${!cat_filter.toggled}`
        );
        cat_filter.toggled = !cat_filter.toggled;

        ReactGA.event({
          category: 'User',
          label: `toggle-category-${category_name}`,
          action: `Toggle County: ${category_name}`,
          value: !cat_filter.toggled ? 1 : 0,
        });
      }
    });
    setFilterCategories(category_filters);
  };

  const toggleTactic = (e) => {
    let tactic_id = parseInt(e.target.id, 10);
    let tactics = new Map(filterTactics);
    let tactic = tactics.get(tactic_id);
    console.debug(`Toggling ${tactic.name} from ${tactic.toggled} to ${!tactic.toggled}`);
    tactic.toggled = !tactic.toggled;
    tactics.set(tactic_id, tactic);
    setFilterTactics(tactics);

    ReactGA.event({
      category: 'User',
      label: `toggle-tactic-${tactic.name}`,
      action: `Toggle Tactic ${tactic.name}`,
      value: !tactic.toggled ? 1 : 0,
    });
  };

  const toggleCounty = (e, t) => {
    let county_id = e.target.id;
    let counties = new Map(filterCounties);
    let county = counties.get(county_id);
    console.debug(`Toggling ${county.display_name} from ${county.toggled} to ${!county.toggled}`);
    county.toggled = !county.toggled;
    counties.set(county_id, county);
    setFilterCounties(counties);

    ReactGA.event({
      category: 'User',
      label: `toggle-county-${county.name}`,
      action: `Toggle County: ${county.name}`,
      value: !county.toggled ? 1 : 0,
    });
  };

  const applyFilters = () => {
    /*
     * apply filters in order of greatest reduction in size
     *
     * 1. type
     * 2. date
     * 3. tactic
     * 4. county
     * 5. search
     *
     * */

    if (raids.data.length === 0) {
      return [];
    }

    // make a copy
    let filtered = [...raids.data];

    // get active categories
    let activeCategories = flatten(
      filterCategories.filter(({toggled}) => toggled).map(({name}) => META_CATEGORY_MAPPING[name])
    );
    filtered = filtered.filter((r) => activeCategories.includes(r.category));

    console.debug(
      `[filter] applied category filters - filtered ${raids.data.length} down to ${filtered.length}`
    );

    // filter by dates
    filtered = filtered.filter(
      (r) =>
        (isAfter(r.date, filterFrom) || isEqual(r.date, filterFrom)) &&
        (isBefore(r.date, filterUntil) || isEqual(r.date, filterUntil))
    );

    console.debug(
      `[filter] applied date filters - filtered ${raids.data.length} down to ${filtered.length}`
    );

    // filter by tactics
    let activeTactics = Array.from(filterTactics.values())
      .filter(({toggled}) => toggled)
      .map((t) => t.id);
    filtered = filtered.filter((r) => r.tactics.map((tactic) => activeTactics.includes(tactic)));
    if (activeTactics.length > 0) {
      filtered = filtered.filter(
        (raid) => raid.tactics.length > 0 && intersection(activeTactics, raid.tactics) > 0
      );
    }

    console.debug(
      `[filter] applied tactic filters - filtered ${raids.data.length} down to ${filtered.length}`
    );

    // filter by county
    //filterCounties
    console.debug(filterCounties);
    let selected_filters = Array.from(filterCounties.values()).filter((c) => c.toggled);
    if (selected_filters.length > 0) {
      let county_filters = selected_filters.map((c) => c.name);
      console.log(county_filters);
      filtered = filtered.filter(
        (raid) =>
          raid.location.state === 'NY' &&
          raid.location.county &&
          intersection(
            county_filters.map((county) => county.toLowerCase().replace('county', '').trim()),
            raid.location.county.toLowerCase().replace('county', '')
          ).length
      );
    }

    // sort by date
    filtered = filtered.sort((a, b) =>
      isBefore(a.date, b.date) ? 1 : isAfter(a.date, b.date) ? -1 : 0
    );

    // console.log(filtered.map(({ date }) => date));
    if (query && query.length >= 3) {
      let _query = query.trim().toLowerCase();
      filtered = filtered.filter(
        (r) =>
          (r.summary && r.summary.trim().toLowerCase().includes(_query)) ||
          (r.story_identifier && r.story_identifier.toLowerCase().includes(query)) ||
          (r.location.name && r.location.name.toLowerCase().includes(query))
      );
    }

    return filtered;
  };

  let filteredRaids = applyFilters();

  return (
    <div
      className="h-full w-full mx-auto flex overflow-hidden bg-white"
      style={{maxWidth: '1440px'}}>
      {/* START Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div className={sidebarOpen ? 'lg:hidden' : 'hidden'}>
        <div className="fixed inset-0 flex z-40">
          {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity easeLinear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity easeLinear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
          {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
          <div
            tabIndex="0"
            className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                id="sidebarToggleX"
                type="button"
                onClick={() => setSidebarOpen(false)}
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Close sidebar</span>
                {/* Heroicon name: outline/x */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pb-4 overflow-y-auto border-r border-gray-700 bg-gray-200">
              {raids.loading ? (
                <>Loading</>
              ) : (
                <Filters
                  minFrom={minFrom}
                  setMinFrom={setMinFrom}
                  maxUntil={maxUntil}
                  setMaxUntil={setMaxUntil}
                  filterFrom={filterFrom}
                  setFilterFrom={setFilterFrom}
                  filterUntil={filterUntil}
                  setFilterUntil={setFilterUntil}
                  filterCategories={filterCategories}
                  setFilterCategories={setFilterCategories}
                  filterTactics={filterTactics}
                  setFilterTactics={setFilterTactics}
                  filterCounties={filterCounties}
                  setFilterCounties={setFilterCounties}
                  toggleCategory={toggleCategory}
                  toggleTactic={toggleTactic}
                  toggleCounty={toggleCounty}
                  reset={resetFilters}
                />
              )}
            </div>
            <div className="flex-shrink-0 flex flex-col border-t border-gray-200 p-2 text-center">
              <p className="mx-auto" style={{fontSize: '.65rem'}}>
                <b>Last updated:</b> {LAST_UPDATED}
              </p>
              <p className="mx-auto" style={{fontSize: '.65rem'}}>
                Copyright ©{' '}
                <a href="//immdefense.org" target="_blank" rel="noreferrer">
                  Immigrant Defense Project
                </a>{' '}
                2022
              </p>
            </div>
          </div>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </div>
      {/* END Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}

      {/* START Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0 border-r border-gray-400">
        <div className="flex flex-col w-64 bg-gray-200">
          {raids.loading ? (
            <>Loading</>
          ) : (
            <Filters
              minFrom={minFrom}
              setMinFrom={setMinFrom}
              maxUntil={maxUntil}
              setMaxUntil={setMaxUntil}
              filterFrom={filterFrom}
              setFilterFrom={setFilterFrom}
              filterUntil={filterUntil}
              setFilterUntil={setFilterUntil}
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
              filterTactics={filterTactics}
              setFilterTactics={setFilterTactics}
              filterCounties={filterCounties}
              setFilterCounties={setFilterCounties}
              toggleCategory={toggleCategory}
              toggleTactic={toggleTactic}
              toggleCounty={toggleCounty}
              reset={resetFilters}
            />
          )}
          <div className="flex-shrink-0 flex flex-col border-t border-gray-400 p-2 text-center">
            <p className="mx-auto" style={{fontSize: '.65rem'}}>
              <b>Last updated:</b> {LAST_UPDATED}
            </p>
            <p className="mx-auto" style={{fontSize: '.65rem'}}>
              Copyright ©{' '}
              <a href="//immdefense.org" target="_blank" rel="noreferrer">
                Immigrant Defense Project
              </a>{' '}
              2022
            </p>
          </div>
        </div>
      </div>
      {/* END Static sidebar for desktop */}

      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main
            id="map"
            className="flex-1 relative z-0 overflow-y-auto focus:outline-none lg:order-last"
            tabIndex="0">
            <MapComponent
              activeRaid={activeRaid}
              categories={categories}
              data={filteredRaids}
              loading={raids.loading}
              searchBarOpen={searchBarOpen}
              setActiveRaid={setActiveRaid}
              setSearchBarOpen={setSearchBarOpen}
              setViewport={setViewport}
              tactics={tactics}
              totalRaidCount={totalRaidCount}
              viewport={viewport}
              totalCount={raids.data.length}
              setSidebarOpen={setSidebarOpen}
              resetAll={() => {
                setActiveRaid(null);
                setViewport(DEFAULT_VIEWPORT);
                resetFilters();
              }}
            />
          </main>
          {searchBarOpen && (
            <aside
              className="hidden relative lg:order-first lg:flex lg:flex-col flex-shrink-0 w-80 xl:w-96 border-r border-gray-200
transition duration-500 ease-in-out
            ">
              <div className="absolute inset-0 flex flex-col">
                <Formik
                  initialValues={{query: ''}}
                  onSubmit={({query}) => {
                    setQuery(query);
                    ReactGA.event({
                      category: 'User',
                      label: 'filters-set-search',
                      action: 'Set Search Filters',
                      value: query,
                    });
                  }}>
                  <Form id="search" className="border-b">
                    <fieldset className="flex p-2" disabled={raids.loading}>
                      <div className="mr-2 flex items-center">
                        <button
                          type="button"
                          className="inline-flex items-center p-0 border border-transparent rounded-full shadow-sm text-white bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-gray-500 hover:text-gray-700"
                          onClick={() => {
                            ReactGA.modalview('/search-help');
                            setSearchHelpModalIsOpen(true);
                          }}>
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>
                      <Field
                        id="query"
                        name="query"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mr-2 border border-1 p-2"
                        placeholder="Search"
                      />
                      <button
                        type="submit"
                        disabled={raids.loading}
                        className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                          raids.loading
                            ? ' cursor-wait bg-gray-600 hover:bg-gray-700 focus:ring-gray-500'
                            : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                        }`}>
                        Search
                      </button>
                    </fieldset>
                  </Form>
                </Formik>
                <div className="flex-grow overflow-scroll">
                  <RaidList
                    data={filteredRaids}
                    loading={raids.loading}
                    setActiveRaid={setActiveRaid}
                    setViewport={setViewport}
                  />
                </div>
              </div>
            </aside>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={searchHelpModalIsOpen}
        onRequestClose={() => setSearchHelpModalIsOpen(false)}
        overlayClassName="react-modal-overlay absolute top-0 right-0 z-50 flex items-center justify-center"
        className="max-w-96 min-w-96"
        style={{content: {height: '320px', width: '420px'}}}>
        <div
          className="bg-white max-w-3xl max-h-96 rounded rounded-md p-6 text-lg"
          style={{maxHeight: '320px', maxWidth: '420px'}}>
          <h2 className="text-2xl font-semibold leading-5 mb-4">About Search</h2>
          <p className="mb-2">
            Search by keyword, geographical location (like zip code) or other terms.
          </p>
          <p className="mb-2">The search results will be show in the list and on the map.</p>
          <p className="mb-2">To clear the search delete the query and press enter.</p>
        </div>
      </ReactModal>
    </div>
  );
};

export default Index;
