/*
 *
 * - 1 Home Raid
 * - 2 Criminal Court
 * - 3 Family Court
 * - 4 Court (Other)
 * - 5 Probation/Parole Arrest
 * - 6 Street Arrest
 * - 7 Workplace Arrest
 * - 8 Car Stop
 * - 9 Shelter Arrest
 * - 10 Other
 *
 */
export const META_CATEGORY_MAPPING = {
  Home: [1],
  Courthouse: [2, 3, 4],
  Street: [6],
  Other: [5, 7, 8, 9, 10]
};

export const MIN_ZOOM = 3;
export const MAX_ZOOM = 13;
export const ACTIVE_RAID_ZOOM = 10;
