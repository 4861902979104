const About = () => (
  <div className="w-screen h-full overflow-scroll lg:p-8 md:p-6 p-4 font-sans">
    <div className="max-w-5xl mx-auto">
      <div className="text-base text-gray-900 fade-in lg:text-lg text-base">
        <h2 className="text-2xl leading-2 font-semibold mb-2 border-b border-white">
          ICEwatch: Raids Tactics Map
        </h2>
        <p className="lg:mb-4 mb-2">
          ICEwatch is a visualization of the tactics and trends in ICE arrests, based on verified
          raids reports that IDP has received since we began monitoring ICE arrest and surveillance
          activities in the New York Metropolitan area in 2013.
        </p>

        <p className="lg:mb-4 mb-2">
          From the reports of ICE raids we’ve received, IDP has been able to interpret and
          extrapolate trends in the policing, arrest, and surveillance tactics used by ICE to target
          and arrest immigrants. With our partners at The Center for Constitutional Rights (CCR), we
          launched ICEwatch in July 2018 so that the information collected can inform communities
          and advocates of ICE's tactics, and to further shed light on ICE’s cruel, inhumane, and
          unjust practices.
        </p>

        <p className="lg:mb-4 mb-2">
          For the purposes of ICEwatch, we define a raid as any instance where ICE agents arrested
          or attempted to arrest a non-citizen. In some of the raids on the map, more than one
          person was arrested.
        </p>

        <p className="lg:mb-4 mb-2">
          Each point on the map is an approximate location based on the zip code or county of the
          reported raid, and NOT an exact address or location. Some reports don't have a zip code or
          exact date listed because they were not provided by the person reporting the raid.
          ICEwatch is not meant to be read as a comprehensive account of ICE raids in the New York
          area, but rather a resource that reflects ICE’s raids activity and tactics. ICEwatch is
          not updated in real time.
        </p>

        <h2 className="text-xl leading-3 font-semibold mb-2 border-b border-white my-1">
          Methodology
        </h2>

        <p className="lg:mb-4 mb-2">
          IDP collects and verifies ICE raid reports through collaborations with community-based
          organizations and legal services providers throughout New York State, as well as through
          calls received on our hotline. New York uniquely offers Regional Immigration Assistance
          Centers (RIAC) in all counties across the state, as well as the New York Immigrant Family
          Unity Project (NYIFUP) in immigration courts. Both initiatives have provided a mechanism
          for IDP to collect firsthand accounts of raids from the individuals arrested by ICE (via
          their attorneys), whose experiences during ICE raids may otherwise not have been reported,
          recorded, or shared. IDP also confirms details of raids reports by speaking with witnesses
          or those with direct knowledge of the raid, like family members.
        </p>

        <p className="lg:mb-4 mb-2">
          For more information on ICE raids, visit{' '}
          <a
            className="text-blue-500 underline"
            eventLabel="toolKitClick"
            rel="noopener noreferrer"
            target="_blank"
            href="https://immdefense.org/raids">
            immdefense.org/raids
          </a>
          .
          <ul className="list-disc list-inside ml-4 mt-2">
            <li>
              This page contains a primer on ICE ruses, reports detailing trends of ICE arrest
              tactics, and Freedom of Information Act (FOIA) documents pertaining to ICE raids.
            </li>
            <li>
              For a comprehensive guide on the history of ICE raids, a summary of raids tactics used
              since 2003, and organizing resources to fight back against ICE raids, see IDP’s and
              CCR’s{' '}
              <a
                className="text-blue-500 underline"
                eventLabel="toolKitClick"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.immigrantdefenseproject.org/raids-toolkit/">
                Defend Against ICE Raids and Community Arrest
              </a>{' '}
              toolkit.
            </li>
          </ul>
        </p>

        <p className="lg:mb-4 mb-2">
          IDP’s Know Your Rights materials are available in multiple languages at{' '}
          <a
            className="text-blue-500 underline"
            eventLabel="trendsReportClick"
            rel="noopener noreferrer"
            target="_blank"
            href="immdefense.org/kyr">
            immdefense.org/kyr
          </a>
          .
        </p>
        <p className="lg:mb-4 mb-2">
          To learn more about IDP's work, visit{' '}
          <a
            className="text-blue-500 underline"
            eventLabel="trendsReportClick"
            rel="noopener noreferrer"
            target="_blank"
            href="//immdefense.org">
            immdefense.org
          </a>
          .
        </p>

        <p className="lg:mb-4 mb-2">
          Follow IDP on Twitter, Instagram, and Facebook at @immdefense
        </p>

        <p className="lg:mb-4 mb-2">
          To learn more about CCR, visit{' '}
          <a
            className="text-blue-500 underline"
            href="https://ccrjustice.org"
            eventLabel="ccrFacebookProfileClick"
            rel="noopener noreferrer"
            target="_blank">
            ccrjustice.org
          </a>
          .
        </p>

        <p className="lg:mb-4 mb-2">Follow CCR on Twitter, Instagram, and Facebook at @theCCR</p>

        <div className="text-center border-t border-blue-100 py-4">
          ICEwatch is best viewed on a desktop computer.
        </div>

        <div className="text-center border-t border-blue-100 py-4 mb-4">
          Assembled by{' '}
          <a
            href="https://www.streetwide.org"
            eventLabel="streetwideDotComClick"
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue-500 underline"
            style={{
              fontVariant: 'small-caps',
            }}>
            Streetwide
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default About;
