import { useState } from "react";

import ReactGA from "react-ga";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { META_CATEGORY_MAPPING } from "../../colors";

const getColor = label => {
  if (Object.keys(META_CATEGORY_MAPPING).includes(label)) {
    return META_CATEGORY_MAPPING[label];
  }
  return META_CATEGORY_MAPPING["Other"];
};

const Filters = ({
  minFrom,
  setMinFrom,
  maxUntil,
  setMaxUntil,
  filterFrom: from,
  setFilterFrom,
  filterUntil: until,
  setFilterUntil,
  filterCategories: categories,
  setFilterCategories,
  filterTactics: tactics,
  setFilterTactics,
  filterCounties: counties,
  setFilterCounties,
  toggleCategory,
  toggleTactic,
  toggleCounty,
  reset
}) => {
  const [dateIsOpen, setDateIsOpen] = useState(true);
  const [categoriesIsOpen, setCategoriesIsOpen] = useState(true);
  const [tacticsIsOpen, setTacticsIsOpen] = useState(false);
  const [countiesIsOpen, setCountiesIsOpen] = useState(false);

  return (
    <>
      <div className="pin-t p-4 border-b border-gray-700 flex justify-between items-center">
        <div className="text-md md:text-xl font-semibold leading-6">
          Filter Raids
        </div>
        <div>
          <button
            className="-m-2 p-1 px-3 rounded rounded-md border border-red-400 bg-red-200 hover:border-red-900 hover:bg-red-300 text-sm text-gray-700"
            onClick={() => {
              reset();
              ReactGA.event({
                category: "User",
                label: "filters-reset",
                action: "Reset All Filters"
              });
            }}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-scroll">
        <div
          className="border-b border-gray-900" //  filter section wrapper
          id="filters-categories"
        >
          <div className="p-1 w-full flex items-center sticky top-0 bg-blue-200">
            <button
              className="p-1 mr-1"
              onClick={() => setDateIsOpen(!dateIsOpen)}
            >
              {dateIsOpen ? "-" : "+"}
            </button>
            <div className="flex-grow flex justify-between items-center">
              <div
                className="text-sm lg:text-base font-semibold cursor-pointer"
                onClick={() => setDateIsOpen(!dateIsOpen)}
              >
                Filter by Date
              </div>
              <button
                className="text-xs font-blue-600 py-1 px-2 border border-blue-400 rounded rounded-sm hover:bg-indigo-300 hover:text-gray-100"
                onClick={() => {
                  setFilterFrom(minFrom);
                  setFilterUntil(maxUntil);

                  ReactGA.event({
                    category: "User",
                    label: "filters-reset-date",
                    action: "Reset Date Filters"
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <form className={`p-3 sm:p-4 ${dateIsOpen ? "" : "hidden"}`}>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="from"
                  className="block text-xs md:text-sm font-medium text-gray-700"
                >
                  From
                </label>
                <div className="mt-1">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={from}
                    onChange={val => {
                      setFilterFrom(val);
                      ReactGA.event({
                        category: "User",
                        label: "filters-set-from",
                        action: "Set From Filter",
                        value: val.getTime() / 1000
                      });
                    }}
                    minDate={minFrom}
                    maxDate={maxUntil}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="until"
                  className="block text-xs md:text-sm font-medium text-gray-700"
                >
                  Until{" "}
                </label>
                <div className="mt-1">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={until}
                    onChange={val => {
                      setFilterUntil(val);

                      ReactGA.event({
                        category: "User",
                        label: "filters-set-until",
                        action: "Set Until Filter",
                        value: val.getTime() / 1000
                      });
                    }}
                    minDate={minFrom}
                    maxDate={maxUntil}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* categories */}
        <div
          className="border-b border-gray-900" //  filter section wrapper
          id="filters-categories"
        >
          <div className="p-1 w-full flex items-center sticky top-0 bg-blue-200">
            <button
              className="p-1 mr-1"
              onClick={() => setCategoriesIsOpen(!categoriesIsOpen)}
            >
              {categoriesIsOpen ? "-" : "+"}
            </button>
            <div className="flex-grow flex justify-between items-center">
              <div
                className="text-sm lg:text-base font-semibold cursor-pointer"
                onClick={() => setCategoriesIsOpen(!categoriesIsOpen)}
              >
                Filter by Type
              </div>
              <button
                className="text-xs font-blue-600 py-1 px-2 border border-blue-400 rounded rounded-sm hover:bg-indigo-300 hover:text-gray-100"
                onClick={() => {
                  setFilterCategories(
                    categories.map(({ name, toggled }) => ({
                      name: name,
                      toggled: true
                    }))
                  );

                  ReactGA.event({
                    category: "User",
                    label: "filters-reset-categories",
                    action: "Reset Category Filters"
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <form className={`p-3 sm:p-4 ${categoriesIsOpen ? "" : "hidden"}`}>
            <fieldset>
              {categories.map(({ name, toggled }) => {
                return (
                  <div
                    key={`category-toggle-container--${name}`}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={toggled}
                      id={name}
                      onChange={toggleCategory}
                    />
                    <label
                      className="flex-1 flex items-center justify-between cursor-pointer"
                      htmlFor={name}
                    >
                      <div>{name === "Other" ? "Other *" : name}</div>
                      <div
                        id={`color-label--${name}`}
                        className="mr1"
                        style={{
                          backgroundColor: getColor(name).fill,
                          width: "15px",
                          height: "15px",
                          borderRadius: "100%"
                        }}
                      />
                    </label>
                  </div>
                );
              })}

              <p className="text-gray-900 text-xs mt-1 mb-2" htmlFor="Other">
                * Car Stop, Shelter, Workplace, Probation/Parole
              </p>
            </fieldset>
          </form>
        </div>
        {/* tactics */}
        <div
          className="border-b border-gray-900" //  filter section wrapper
          id="filters-categories"
        >
          <div className="p-1 w-full flex items-center sticky top-0 bg-blue-200">
            <button
              className="p-1 mr-1"
              onClick={() => setTacticsIsOpen(!tacticsIsOpen)}
            >
              {tacticsIsOpen ? "-" : "+"}
            </button>
            <div className="flex-grow flex justify-between items-center">
              <div
                className="text-sm lg:text-base font-semibold cursor-pointer"
                onClick={() => setTacticsIsOpen(!tacticsIsOpen)}
              >
                Filter by Tactic
              </div>
              <button
                className="text-xs font-blue-600 py-1 px-2 border border-blue-400 rounded rounded-sm hover:bg-indigo-300 hover:text-gray-100"
                onClick={() => {
                  let newTactics = new Map();
                  Array.from(tactics.entries()).forEach(([key, value]) => {
                    newTactics.set(key, { ...value, toggled: false });
                  });
                  setFilterTactics(newTactics);

                  ReactGA.event({
                    category: "User",
                    label: "filters-reset-tactics",
                    action: "Reset Tactics Filters"
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <form className={`p-3 sm:p-4 ${tacticsIsOpen ? "" : "hidden"}`}>
            <fieldset disabled={false}>
              {Array.from(tactics.values()).map(({ id, name, toggled }) => (
                <div key={`tactic-option-toggle-container--${id}`}>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={toggled}
                    onChange={toggleTactic}
                    id={id}
                  />
                  <label htmlFor={id}>{name}</label>
                </div>
              ))}
            </fieldset>
          </form>
        </div>
        {/* counties */}
        <div
          className="border-b border-gray-900" //  filter section wrapper
          id="filters-counties"
        >
          <div className="p-1 w-full flex items-center sticky top-0 bg-blue-200">
            <button
              className="p-1 mr-1"
              onClick={() => setCountiesIsOpen(!countiesIsOpen)}
            >
              {countiesIsOpen ? "-" : "+"}
            </button>
            <div className="flex-grow flex justify-between items-center">
              <div
                className="text-sm lg:text-base font-semibold cursor-pointer"
                onClick={() => setCountiesIsOpen(!countiesIsOpen)}
              >
                Filter by NY County
              </div>
              <button
                className="text-xs font-blue-600 py-1 px-2 border border-blue-400 rounded rounded-sm hover:bg-indigo-300 hover:text-gray-100"
                onClick={() => {
                  let newCounties = new Map();
                  Array.from(counties.entries()).forEach(([key, value]) => {
                    newCounties.set(key, { ...value, toggled: false });
                  });
                  setFilterCounties(newCounties);

                  ReactGA.event({
                    category: "User",
                    label: "filters-reset-counties",
                    action: "Reset Counties Filters"
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <form className={`p-3 sm:p-4 ${countiesIsOpen ? "" : "hidden"}`}>
            <fieldset disabled={false}>
              {Array.from(counties.values()).map(({ id, name, toggled }) => (
                <div key={`tactic-option-toggle-container--${id}`}>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={toggled}
                    onChange={toggleCounty}
                    id={id}
                  />
                  <label htmlFor={id}>{name}</label>
                </div>
              ))}
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

export default Filters;
