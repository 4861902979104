import { useEffect } from "react";
import { Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";

import IDPLogo from "./img/idp-logo.png";
import CCRLogo from "./img/ccr-logo-sm.jpeg";

import Index from "./containers/Index";
import About from "./containers/About";
import Key from "./containers/Key";

var history = createBrowserHistory();

history.listen(location => {
  window.ga("set", "page", location.pathname + location.search);
  window.ga("send", "pageview");
});

const App = () => {
  useEffect(() => {
    let headerEl = document.getElementById("header");
    let mainEl = document.getElementById("main");
    mainEl.style = `height: calc(100vh - ${headerEl.scrollHeight}px)`;
  });
  return (
    <Router history={history}>
      <div
        id="content-wrapper"
        className="w-screen h-screen max-h-screen overflow-hidden flex flex-col bg-gray-50"
      >
        <header className="w-full border-b border-gray-400" id="header">
          <div
            className="mx-auto px-4 py-2 flex justify-between items-center"
            style={{ maxWidth: "1440px" }}
          >
            <div id="title-and-nav" className="flex flex-1 items-center">
              <Link
                to="/"
                className="lg:text-3xl md:text-2xl text-xl leading-6 font-semibold md:font-semibold sm:leading-5 mr-6 flex-grow"
              >
                <h1>ICEwatch</h1>
              </Link>
              <nav
                className="flex justify-between font-semibold text-sm md:text-base"
                id="navigation"
              >
                <div className="p-2 mr-2 md:p-4 md:mr-4 last:mr-0 hover:bg-gray-200 rounded-sm md:rounded-md">
                  <Link to="/">Home</Link>
                </div>
                <div className="p-2 mr-2 md:p-4 md:mr-4 last:mr-0 hover:bg-gray-200 rounded-sm md:rounded-md">
                  <Link to="/about">About</Link>
                </div>
                <div className="p-2 mr-2 md:p-4 md:mr-4 last:mr-0 hover:bg-gray-200 rounded-sm md:rounded-md">
                  <Link to="/key">Key</Link>
                </div>
              </nav>
            </div>
            <div id="logos" className="sm:flex justify-between hidden">
              <a
                href="https://immdefense.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IDPLogo} className="h-10 mr-4" alt="IDP Logo" />
              </a>
              <a
                href="https://ccrjustice.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={CCRLogo} className="h-10" alt="CCR Logo" />
              </a>
            </div>
          </div>
        </header>

        <main className="flex-grow" id="main">
          <Switch>
            <Route path="/" exact={true}>
              <Index />
            </Route>
            <Route path="/about" exact={true}>
              <About />
            </Route>
            <Route path="/key" exact={true}>
              <Key />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
};

export default App;
