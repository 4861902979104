import { useEffect } from "react";
import "./mapbox";
import ReactMapboxGl, {
  ZoomControl,
  Cluster,
  Marker,
  Popup
} from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { COLORS, layers } from "../../colors";
import { MIN_ZOOM, MAX_ZOOM, ACTIVE_RAID_ZOOM } from "../../constants";

const MAX_CLUSTER_WIDTH = 250;
const MIN_CLUSTER_WIDTH = 20;

const easeInOutQuad = x =>
  x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiaW1taWdyYW50ZGVmZW5zZSIsImEiOiJjajg0ejM1MTQwZm15MnFwZHRhd252bHZ5In0.WinRewL156hmuGfZhCA8Zg",
  scrollZoom: true,
  touchZoomRotate: false,
  attributionControl: false,
  dragRotate: false,
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM
});

const MapComponent = ({
  viewport,
  setViewport,
  data = [],
  activeRaid = null,
  setActiveRaid,
  categories,
  tactics,
  totalRaidCount,
  searchBarOpen,
  setSearchBarOpen,
  setSidebarOpen,
  sidebarOpen,
  totalCount,
  resetAll
}) => {
  const raidCount = data.length;

  const getPopup = raid => {
    let width = raid.summary ? "440px" : "320px";
    let { fill } = COLORS[raid.category];
    return (
      <Popup
        coordinates={[raid.lng__jittered, raid.lat__jittered]}
        anchor="bottom"
        id={`mapbox-gl--popup--${raid.uuid}`}
        key={`mapbox-gl--popup--${raid.uuid}`}
        offset={{
          "bottom-left": [12, -38],
          bottom: [0, -15],
          "bottom-right": [-12, -38]
        }}
        className="rounded-lg text-sm"
        style={{
          maxWidth: "calc(100vw - 20px)",
          width: width,
          zIndex: 5,
          borderTop: `5px solid ${fill}`
        }}
      >
        <div
          className="bg-white"
          id={`mapbox-gl--content--${raid.uuid}`}
          key={`mapbox-gl--content--${raid.uuid}`}
        >
          <div className="flex justify-between items-center pb-1 mb-1">
            <div className="bold font-bold">
              {categories.filter(c => c.id === raid.category)[0].name} -{" "}
              {raid.story_identifier}
            </div>
            <button
              className="cursor-pointer"
              onClick={() => setActiveRaid(null)}
            >
              [X]
            </button>
          </div>

          <div className="flex justify-between mb-1 text-gray-500">
            <div>{raid.location.name.replace(", USA", "") || raid.name}</div>
            <div>
              {raid.date_is_approximate
                ? raid.date__approximation_str
                : raid.date__str}
            </div>
          </div>
        </div>

        {raid.summary ? (
          <div
            className="raid-summary font-sans overflow-y-scroll"
            style={{ maxHeight: "100px" }}
          >
            {raid.summary}
          </div>
        ) : (
          ""
        )}

        {raid.tactics.length > 0 && (
          <div className="mt-2 pt-2 border-t border-gray-400 text-sm">
            <div className="font-sans font-semibold">Tactics</div>
            <ul className="font-sans list-disc pl-4">
              {raid.tactics.map(id => (
                <li key={id}>{tactics.filter(t => t.id === id)[0].name}</li>
              ))}
            </ul>
          </div>
        )}
      </Popup>
    );
  };

  const clusterMarker = ({
    coordinates,
    pointCount,
    category,
    categoryCount
  }) => {
    // determine cluster size. value must be between [MIN_CLUSTER_WIDTH, MAX_CLUSTER_WIDTH]

    // first we calculate the proportion of raids in this category. this is the first scaling factor.
    let categoryClusterSizeFactor = categoryCount / totalCount;

    // then we calculate the size of this specific cluster using the pointCount
    let pointClusterSize =
      easeInOutQuad((pointCount / categoryCount) * categoryClusterSizeFactor) *
      MAX_CLUSTER_WIDTH;
    let radius = Math.max(pointClusterSize, MIN_CLUSTER_WIDTH);

    return (
      <Marker
        key={`${category}--${categoryCount}-${pointCount}-${Math.random()}`}
        coordinates={coordinates}
        className="cursor-pointer"
        style={{
          cursor: "pointer",
          borderRadius: "100%",
          backgroundColor: `${layers[category].paint["circle-color"]}`,
          border: "1px solid #222323",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${radius}px`,
          height: `${radius}px`
        }}
      >
        {pointCount}
      </Marker>
    );
  };

  const structureRaids = data => {
    let categories = Array.from(new Set(data.map(r => r.category)));

    let structured = {};
    categories.forEach(c => {
      structured[c] = data.filter(r => r.category === c);
    });
    return structured;
  };

  const _setViewport = event => {
    // console.log(event);
    console.debug(
      `SettingViewPort: ${JSON.stringify(event.transform._center)}`
    );
    let { lat: latitude, lng: longitude } = event.transform._center;
    let { _zoom: zoom } = event.transform;
    setViewport({ latitude, longitude, zoom });
  };

  let structured = structureRaids(data);

  useEffect(() => {
    setViewport(viewport);
    window.dispatchEvent(new Event("resize"));
  }, [searchBarOpen]);

  return (
    <div className="absolute inset-0">
      <div className="relative h-full">
        <div
          className="fixed pin-t text-xs text-gray-700 text-center static flex justify-between items-center"
          style={{ zIndex: "101" }}
          id="legend"
        >
          <button
            id="sidebar-toggle"
            className="lg:hidden h-full bg-green-500 hover:bg-green-700 static z-100 p-2 text-white rounded-full ml-2 mt-2"
            onClick={() => setSidebarOpen(true)}
          >
            {/* Heroicon name: outline/menu */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <button
            id="search-toggle"
            className="hidden lg:block h-full bg-blue-500 hover:bg-blue-700 static z-100 p-2 text-white rounded-full ml-2 mt-2"
            onClick={() => setSearchBarOpen(!searchBarOpen)}
          >
            {searchBarOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
          </button>
          <button
            id="home"
            className="h-full bg-gray-500 hover:bg-gray-700 static z-100 p-2 mr-2 text-white rounded-full mx-2 mt-2"
            onClick={() => {
              resetAll();
              console.log("home button clicked");
            }}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
          </button>
          <div
            className="h-full text-gray-900 text-center flex items-center text-lg font-semibold"
            style={{
              textShadow: "2px 2px 3px rgba(255, 255, 255, .8)"
            }}
          >
            Showing {raidCount} of {totalCount} Raids
          </div>
        </div>
        <div className="flex flex-col h-full">
          <Map
            className="h-full flex-grow"
            center={[viewport.longitude, viewport.latitude]}
            zoom={[viewport.zoom]}
            onDragEnd={_setViewport}
            onZoomEnd={_setViewport}
            // eslint-disable-next-line
            style="mapbox://styles/immigrantdefense/cj84yy9vv15yy2snbkdrh3gqi"
            onClick={() => setActiveRaid(null)}
          >
            <ZoomControl
              position="top-right"
              id="zoom-controls"
              onControlClick={(map, zoomDiff) => {
                setViewport({ ...viewport, zoom: viewport.zoom + zoomDiff });
              }}
            />

            {activeRaid && getPopup(activeRaid)}
            {Object.keys(structured)
              .filter(category => structured[category].length) // don't even render layers with no raids
              .map((category, i) => {
                let categoryCount = structured[category].length;
                return (
                  <Cluster
                    key={`cluster--category-${category}--${i}`}
                    zoomOnClick={true}
                    zoomOnClickPadding={40}
                    maxZoom={12}
                    ClusterMarkerFactory={(coordinates, pointCount) =>
                      clusterMarker({
                        coordinates,
                        pointCount,
                        category,
                        categoryCount
                      })
                    }
                  >
                    {structured[category].map((raid, i) => (
                      <Marker
                        key={`layer-${category}--feature--raid--${
                          raid.uuid
                        }--${i}`}
                        data-feature={raid}
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: `${
                            layers[category].paint["circle-color"]
                          }`,
                          border: "1px solid black",
                          borderRadius: "100%",
                          cursor: "pointer"
                        }}
                        coordinates={[raid.lng__jittered, raid.lat__jittered]}
                        onClick={() => {
                          setActiveRaid(raid);
                          setViewport({
                            ...viewport,
                            latitude: raid.lat,
                            longitude: raid.lng,
                            // Set to less than max zoom
                            zoom: ACTIVE_RAID_ZOOM
                          });
                        }}
                      />
                    ))}
                  </Cluster>
                );
              })}
          </Map>
          <div
            className="text-xs text-gray-700 text-center static pin-b p-2"
            id="disclaimer"
          >
            <p>
              The locations accompanying each story on the map are based on the
              zip code or county of the reported raid, and are NOT exact
              locations. ICEwatch is meant to reflect raid activity and tactics
              as verified by IDP. ICEwatch is not a comprehensive map of ICE
              raids and should not be thought of or used as such.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
