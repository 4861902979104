import { COLORS } from "../../colors";
import { ACTIVE_RAID_ZOOM } from "../../constants";

const RaidList = ({
  data = [],
  loading = true,
  setActiveRaid,
  setViewport
}) => {
  return (
    <div className="w-full h-full">
      {loading ? (
        <div>Loading</div>
      ) : (
        data.map((raid, i) => {
          let {
            id,
            story_identifier,
            category,
            date__approximation_str,
            date__str,
            date_is_approximate,
            location,
            name,
            summary
          } = raid;
          return (
            <div
              key={`${id}--${i}`}
              className="p-3 border-b lg:text-md text-sm hover:bg-blue-50 cursor-pointer"
              style={{ borderLeft: `4px solid ${COLORS[category].fill}` }}
              onClick={() => {
                setActiveRaid(raid);
                setViewport({
                  latitude: raid.lat,
                  longitude: raid.lng,
                  zoom: ACTIVE_RAID_ZOOM
                });
              }}
            >
              <div className="flex justify-between font-semibold leading-1 mb-1 pb-1">
                <div>{story_identifier}</div>
                <div>
                  {date_is_approximate ? date__approximation_str : date__str}
                </div>
              </div>

              <div className="min-h-32 max-h-44 overflow-ellipsis overflow-hidden">
                <span className="text-gray-600 italic">
                  {(location.name || name).replace(", USA", "")}:{" "}
                </span>
                {summary.length > 90 ? summary.slice(0, 90) + " ... " : summary}
              </div>
            </div>
          );
        })
      )}
      {data.length === 0 && (
        <div
          id="no-results-message"
          className="lg:text-md text-sm text-center p-6 font-mono font-semibold text-gray-600"
        >
          No Results Found
        </div>
      )}
    </div>
  );
};

export default RaidList;
