import { parse, parseISO, format, min, max, subDays, addDays } from "date-fns";

/*
 *
 * - 1 Home Raid
 * - 2 Criminal Court
 * - 3 Family Court
 * - 4 Court (Other)
 * - 5 Probation/Parole Arrest
 * - 6 Street Arrest
 * - 7 Workplace Arrest
 * - 8 Car Stop
 * - 9 Shelter Arrest
 * - 10 Other
 *
 */
export const META_CATEGORY_MAPPING = {
  Home: [1],
  Courthouse: [2, 3, 4],
  Street: [6],
  Other: [5, 7, 8, 9, 10]
};

const alphabeticalComparator = (a, b) => {
  if (a.display_name > b.display_name) {
    return 1;
  } else if (a.display_name < b.display_name) {
    return -1;
  }
};

const jitter = number => {
  let rnd = Math.random() * 0.00125;
  if (Math.random() > 0.5) {
    return number + rnd;
  } else {
    return number - rnd;
  }
};

const COUNTY_NAME_MAP = {
  "Kings County": "Kings County (Brooklyn)",
  "Richmond County": "Richmond County (Staten Island)",
  "New York County": "New York County (Manhattan)"
};

const augmentModel = raid => {
  let date = parse(raid.date, "yyyy-MM-dd", new Date());
  let date__str = format(date, "MMMM d, yyyy");
  let date__approximation_str = format(date, "MMMM yyyy");
  return {
    ...raid,
    created_on: parseISO(raid.created_on),
    updated_on: parseISO(raid.updated_on),
    date: date,
    date__str: date__str,
    date__approximation_str: date__approximation_str
  };
};

export const processData = data => {
  // get unique set of locations based on location place id
  let location_place_ids = [];
  let locations = [];
  data.raids
    .map(({ location }) => location)
    .forEach(loc => {
      if (!location_place_ids.includes(loc.geodata.place_id)) {
        locations.push({ ...loc, id: loc.geodata.place_id });
        location_place_ids.push(loc.geodata.place_id);
      }
    });

  console.info(`pulled out ${locations.length} locations`);

  let raids = data["raids"];
  let augmentedRaids = raids
    .filter(raid => raid.category)
    .map(raid => {
      return {
        ...augmentModel(raid),
        report_citation_reference: raid.report_citation_reference || "",
        summary: raid.summary || "",
        geometry: { coordinates: raid.location.geodata.geometry.location },
        lat: raid.location.geodata.geometry.location.lat,
        lat__jittered: jitter(raid.location.geodata.geometry.location.lat),
        lng: raid.location.geodata.geometry.location.lng,
        lng__jittered: jitter(raid.location.geodata.geometry.location.lng)
      };
    });

  // hold in vars to set state later
  let raids_map = new Map();
  augmentedRaids.forEach(raid => {
    raids_map.set(raid.uuid, raid);
  });
  let new_state = {
    raids: augmentedRaids,
    raids_map: raids_map,
    locations: locations,
    tactics: data["tactics"],
    categories: data["categories"]
  };

  // Determine Filter States
  let category_filters = Object.keys(META_CATEGORY_MAPPING).map(c => {
    return { name: c, toggled: true };
  });

  let ny_counties = locations.filter(l => l.state === "NY" && l.county);
  console.info(`pulled out ${ny_counties.length} ny counties`);
  let counties = {};
  // let counties = ny_counties.map(l => l.county);
  ny_counties.forEach(location => {
    if (Object.keys(counties).includes(location.county)) {
      // average lat/lng
      let { lat, lng } = counties[location.county];
      lat = (lat + location.geodata.geometry.location.lat) / 2;
      lng = (lng + location.geodata.geometry.location.lng) / 2;
      counties[location.county]["lat"] = lat;
      counties[location.county]["lng"] = lng;
    } else {
      let { id } = location;
      let { lat, lng } = location.geodata.geometry.location;
      counties[location.county] = { id, lat, lng };
    }
  });

  let county_list = Object.keys(counties)
    .map(county => {
      let display_name = county;
      if (Object.keys(COUNTY_NAME_MAP).includes(county)) {
        display_name = COUNTY_NAME_MAP[county];
      }
      return {
        name: county,
        display_name: display_name,
        ...counties[county]
      };
    })
    .sort(alphabeticalComparator);

  let raidDates = augmentedRaids.map(r => r.date);
  let minFrom = subDays(min(raidDates), 1);
  let maxUntil = addDays(max(raidDates), 1);

  let tactic_map = new Map();
  data.tactics.forEach(tactic =>
    tactic_map.set(tactic.id, { ...tactic, toggled: false })
  );

  let county_map = new Map();
  county_list.forEach(county =>
    county_map.set(county.id, { ...county, toggled: false })
  );
  return {
    ...new_state,
    filters: {
      from: minFrom,
      minFrom: minFrom,
      until: maxUntil,
      maxUntil: maxUntil,
      categories: category_filters,
      tactics: tactic_map,
      counties: county_map
    },
    loading: false
  };
};
