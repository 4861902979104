const tactics = {
  Surveillance:
    "ICE tracks individuals through various means including massive databases, cell phone location data, tracking people's movement, and/or targeted monitoring, including staking out individuals' homes, cars, and workplaces.",
  "Warrantless Entry":
    "When ICE enters a home without permission and without a judicial warrant.",
  Ruse:
    "When ICE uses deceptive tactics and lies to get permission to enter a home or to gather information about where to find someone. A common ruse includes pretending to be local police investigating a fictional crime or fictional suspect.",
  "Collateral Arrest":
    "When ICE arrests someone other than the non-citizen they have targeted for arrest.",
  "Use of Force":
    "When ICE uses physical force, such as drawing guns, kicking doors, pushing individuals) and/or yell at or threaten individuals"
};

const Key = () => (
  <div className="w-screen p-4 h-full overflow-y-auto">
    <div className="max-w-3xl mx-auto mb-8">
      <div className="mb-3" id="acronyms">
        <h2 className="text-2xl leading-2 font-semibold mb-2 border-b border-white">
          Acronym Key
        </h2>
        <ul className="mb-2">
          <li>
            <b>CBP</b>: Customs and Border Protection
          </li>
          <li>
            <b>DEA</b>: Drug Enforcement Administration
          </li>
          <li>
            <b>HSI</b>: Homeland Security Investigations
          </li>
          <li>
            <b>ICE</b>: Immigration and Customs Enforcement
          </li>
          <li>
            <b>IDP</b>: Immigrant Defense Project
          </li>
          <li>
            <b>NYPD</b>: New York Police Department
          </li>
          <li>
            <b>OCA</b>: New York State Office of Court Administration (New York
            State court officers are employees of OCA)
          </li>
          <li>
            <b>USCIS</b>: United States Immigration and Citizenship Services
          </li>
          <li>
            <b>26 Federal Plaza</b>: NYC ICE Field Office. This building also
            houses USCIS offices as well as an immigration court.
          </li>
        </ul>
        <p className="mb-2">
          For the purposes of ICEwatch, we define a raid as any instance where
          ICE agents arrested or attempted to arrest a non-citizen. In some of
          the raids on the map, more than one person was arrested.
        </p>
      </div>

      <div className="mb-2" id="tactics">
        <h2 className="text-2xl leading-2 font-semibold mb-2 border-b border-white">
          Tactics
        </h2>
        <p className="mb-1">
          We have highlighted the following tactics often used by ICE during
          raids. ICE usually uses one or more of these tactics when arresting or
          attempting to arrest non-citizens:
        </p>
        <ul className="list-inside">
          {Object.keys(tactics).map(name => (
            <li key={name} className="mb05">
              <b>{name}</b>: {tactics[name]}
            </li>
          ))}
        </ul>
      </div>

      <div className="mb-2" id="outbound-links">
        <p className="lg:mb-4 mb-2 font-bold">
          For detailed summaries on each of the raids tactics highlighted on the
          map, see{" "}
          <a
            className="underline"
            eventLabel="trendsReportClick"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.immigrantdefenseproject.org/wp-content/uploads/ICEwatch-Trends-Report.pdf"
          >
            IDP's 2018&nbsp;<i>ICEwatch Trends Report</i>
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default Key;
