/*
 * Map of Tactic to Color
 * - color is a set of rgb values
 *
 * */
const default_palette_color = "#aaaaaa";
const home_raid_color = "#bd3b47";
const courthouse_arrest_color = "#5477c0";
const street_arrest_color = "#fec42c";

const default_palette_colors = {
  fill: default_palette_color
};

const home_raid_colors = {
  fill: home_raid_color
};

const courthouse_arrest_colors = {
  fill: courthouse_arrest_color
};

const street_arrest_colors = {
  fill: street_arrest_color
};

/*
 *
 * - 1 Home Raid
 * - 2 Criminal Court
 * - 3 Family Court
 * - 4 Court (Other)
 * - 5 Probation/Parole Arrest
 * - 6 Street Arrest
 * - 7 Workplace Arrest
 * - 8 Car Stop
 * - 9 Shelter Arrest
 * - 10 Other
 *
 */
export const COLORS = {
  1: home_raid_colors,
  2: courthouse_arrest_colors,
  3: courthouse_arrest_colors,
  4: courthouse_arrest_colors,
  5: default_palette_colors,
  6: street_arrest_colors,
  7: default_palette_colors,
  8: default_palette_colors,
  9: default_palette_colors,
  10: default_palette_colors
};

export const META_CATEGORY_MAPPING = {
  Home: home_raid_colors,
  Courthouse: courthouse_arrest_colors,
  Street: street_arrest_colors,
  Other: default_palette_colors
};

// map layer bits
const circle_paint = {
  "circle-radius": {
    base: 8,
    stops: [[7, 6], [8, 7], [9, 8], [10, 9], [11, 10], [12, 13]]
  },
  "circle-stroke-width": 1,
  "circle-stroke-color": "#fff"
};

export const layers = {
  1: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": home_raid_color }
  },
  2: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": courthouse_arrest_color }
  },
  3: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": courthouse_arrest_color }
  },
  4: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": courthouse_arrest_color }
  },
  5: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": default_palette_color }
  },
  6: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": street_arrest_color }
  },
  7: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": default_palette_color }
  },
  8: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": default_palette_color }
  },
  9: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": default_palette_color }
  },
  10: {
    type: "circle",
    color: "#8132AB",
    paint: { ...circle_paint, "circle-color": default_palette_color }
  }
};
