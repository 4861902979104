import React from "react";
import ReactDOM from "react-dom";

import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import "./index.css";

// initialize sentry
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

// initialize google analytics
ReactGA.initialize("UA-26847615-4", {
  debug: window.location.hostname === "localhost",
  titleCase: false
});

// load react app
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
